<template lang="pug">
    BannerGeral(:contents="contents").bannerTexto
        .text(v-html="parseContent(contents.content.text.pt)", v-if="contents.content.text && contents.content.text.pt")
</template>

<script>
import { components, props, parseContent } from '@/mixins/components'

export default {
    name: "section-banner-texto",
    props,
    components,
    methods: {
        parseContent,
    },
}
</script>

<style lang="stylus" scoped src="./BannerTexto.styl"></style>
